import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0",
    overflow: "auto",
  },
  confirmBtn: {
    color: "white",
    width: "100%",
    height: "auto",
    padding: "1rem",
    margin: "0 2rem",
    backgroundColor: "#D2B48C",
    "&:hover": {
      backgroundColor: "#C4A484",
    },
  },
  cancelBtn: {
    color: "white",
    width: "100%",
    height: "auto",
    padding: "1rem",
    margin: "0 2rem",
    backgroundColor: "#D2B48C",

    "&:hover": {
      backgroundColor: "#C4A484",
    },
  },
  groupPass: {
    marginTop: "24px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0",
    },
    width: "100%",
  },
  tableBorder: {
    borderTop: "2px solid black",
  },
  customCell: {
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontSize: ".6rem",
    // fontSize: ".8rem",
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      fontSize: ".8rem",
    },
  },
  disabledCell: {
    color: "gray",
    background: "whitesmoke",
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontSize: ".6rem",
    // fontSize: ".8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: ".8rem",
    },
  },
  categoryRow: {
    textTransform: "uppercase",
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    fontSize: ".8rem",
    fontWeight: "bold",
    letterSpacing: ".1rem",
    lineHeight: "1.54",
    backgroundColor: "#f0f0f0",
  },
  form: {
    width: "100%",
    padding: 0,
    margin: 0,
  },
  tablecontainer: {
    marginTop: "1rem",
    width: "100%",
    // width: "max-content",
  },
  formlabel: {
    "& .MuiTypography-root": {
      fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
    },
  },
  grid: {
    width: "300px",
    alignSelf: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      width: "600px",
    },
  },
  huf: {
    width: "6rem",
  },
  controlBtn: {
    width: "98%",
  },
  date: {
    width: "10rem",
  },
}));
export const CardTitle = styled.h1`
  text-align: center;
`;
