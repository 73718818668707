/** @format */

import { useRef, useEffect } from "react";

import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    useTheme,
    //MenuItem
} from "@material-ui/core";

import { Form, Formik } from "formik";
import billingValidationSchema from "validation/Registration/BillingSchema";

import { useStyles } from "./styles";

import PostalCodeFields from "../PostalCodeFields/PostalCodeFields";
import { errorToast, successToast } from "components/common/Toast/Toast";

const BillingForm = ({
    billingData,
    setBillingData,
    contactData,
    handleBack,
    setSubmitted,
    newsAccept,
    setNewsAccept,
    subscribeNewsletter,
}) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const formikRef = useRef(null);

    useEffect(() => {
        if (!billingData?.billingCity && billingData?.billingCity !== "") {
            setBillingData({
                ...billingData,
                billingCity: contactData?.contactCity,
            });
        }
    }, [contactData, setBillingData]);

    useEffect(() => {
        formikRef.current.setFieldValue(
            "billingCity",
            billingData?.billingCity
        );
        formikRef.current.setFieldValue(
            "billingZipCode",
            billingData?.billingZipCode
        );
    }, [billingData]);

    return (
        <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={{
                companyName: billingData?.companyName || "",
                billingVatNumber: billingData?.billingVatNumber || "",
                billingAccountNumber: billingData?.billingAccountNumber || "",
                billingRegisterNumber: billingData?.billingRegisterNumber || "",
                billingCountry:
                    billingData?.billingCountry || contactData.contactCountry,
                billingCity:
                    billingData?.billingCity || contactData.contactCity,
                billingZipCode:
                    billingData?.billingZipCode || contactData.contactZipCode,
                billingStreetAndNumber:
                    billingData?.billingStreetAndNumber ||
                    contactData.contactStreetAndNumber,
                // billingVendor: billingData?.billingVendor || "",
                // APIKey: billingData?.APIKey || "",
                // clientId: billingData?.clientId || "",
            }}
            validationSchema={billingValidationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
                if (newsAccept) {
                    let value = contactData;
                    await subscribeNewsletter({value}).then((res) => 
                    {
                        if(res){
                            successToast("Hírlevélre való feliratkozás sikeres");
                            setSubmitting(true);
                            setBillingData(values);
                            setSubmitted(true);
                            setSubmitting(false);
                        }else{
                            errorToast("Hírlevélre való feliratkozás sikertelen");
                        }
                    });
                } else {
                    setSubmitting(true);
                    setBillingData(values);
                    setSubmitted(true);
                    setSubmitting(false);
                }
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                resetForm,
            }) => (
                <Form className={classes.formPart}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" className={classes.label}>
                                Számlázási név
                            </Typography>
                            <TextField
                                variant="standard"
                                
                                className={classes.input}
                                required
                                autoFocus
                                id="companyName"
                                name="companyName"
                                value={values.companyName}
                                onChange={(e) => {
                                    handleChange(e);
                                    setBillingData({
                                        ...billingData,
                                        companyName: e.target.value,
                                    });
                                }}
                                error={
                                    touched.companyName &&
                                    Boolean(errors.companyName)
                                }
                                helperText={
                                    touched.companyName && errors.companyName
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" className={classes.label}>
                                Adószám
                            </Typography>
                            <TextField
                                variant="standard"
                                
                                className={classes.input}
                                required
                                id="billingVatNumber"
                                name="billingVatNumber"
                                value={values.billingVatNumber}
                                onChange={(e) => {
                                    handleChange(e);
                                    setBillingData({
                                        ...billingData,
                                        billingVatNumber: e.target.value,
                                    });
                                }}
                                error={
                                    touched.billingVatNumber &&
                                    Boolean(errors.billingVatNumber)
                                }
                                helperText={
                                    touched.billingVatNumber &&
                                    errors.billingVatNumber
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" className={classes.label}>
                                Nyilvántartási szám
                            </Typography>
                            <TextField
                                variant="standard"
                                
                                className={classes.input}
                                required
                                id="billingRegisterNumber"
                                name="billingRegisterNumber"
                                value={values.billingRegisterNumber}
                                onChange={(e) => {
                                    handleChange(e);
                                    setBillingData({
                                        ...billingData,
                                        billingRegisterNumber: e.target.value,
                                    });
                                }}
                                error={
                                    touched.billingRegisterNumber &&
                                    Boolean(errors.billingRegisterNumber)
                                }
                                helperText={
                                    touched.billingRegisterNumber &&
                                    errors.billingRegisterNumber
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" className={classes.label}>
                                Bankszámlaszám
                            </Typography>
                            <TextField
                                variant="standard"
                                
                                className={classes.input}
                                required
                                id="billingAccountNumber"
                                name="billingAccountNumber"
                                value={values.billingAccountNumber}
                                onChange={(e) => {
                                    handleChange(e);
                                    setBillingData({
                                        ...billingData,
                                        billingAccountNumber: e.target.value,
                                    });
                                }}
                                error={
                                    touched.billingAccountNumber &&
                                    Boolean(errors.billingAccountNumber)
                                }
                                helperText={
                                    touched.billingAccountNumber &&
                                    errors.billingAccountNumber
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                className={classes.subtitle}
                            >
                                Számlázási cím
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            style={{
                                marginLeft: "0.25rem",
                                marginRight: "-0.25rem",
                            }}
                        >
                            {formikRef?.current && (
                                <PostalCodeFields
                                    data={billingData}
                                    setData={setBillingData}
                                    billing={true}
                                    handleChange={handleChange}
                                    zipHelper={
                                        formikRef?.current?.touched
                                            ?.billingZipCode
                                    }
                                    zipError={
                                        formikRef?.current?.errors
                                            ?.billingZipCode
                                    }
                                    cityHelper={
                                        formikRef?.current?.touched?.billingCity
                                    }
                                    citypError={
                                        formikRef?.current?.errors?.billingCity
                                    }
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" className={classes.label}>
                                Utca, házszám
                            </Typography>
                            <TextField
                                variant="standard"
                                
                                className={classes.input}
                                required
                                id="billingStreetAndNumber"
                                name="billingStreetAndNumber"
                                fullWidth
                                autoComplete="shipping address-line1"
                                value={values.billingStreetAndNumber}
                                onChange={(e) => {
                                    handleChange(e);
                                    setBillingData({
                                        ...billingData,
                                        billingStreetAndNumber: e.target.value,
                                    });
                                }}
                                error={
                                    touched.billingStreetAndNumber &&
                                    Boolean(errors.billingStreetAndNumber)
                                }
                                helperText={
                                    touched.billingStreetAndNumber &&
                                    errors.billingStreetAndNumber
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" className={classes.label}>
                                Ország
                            </Typography>
                            <TextField
                                variant="standard"
                                disabled
                                
                                className={classes.input}
                                required
                                id="billingCountry"
                                name="billingCountry"
                                fullWidth
                                autoComplete="shipping country"
                                value={values.billingCountry}
                                onChange={handleChange}
                                error={
                                    touched.billingCountry &&
                                    Boolean(errors.billingCountry)
                                }
                                helperText={
                                    touched.billingCountry &&
                                    errors.billingCountry
                                }
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
              <Typography variant="h4" className={classes.subtitle}>
                Számlakibocsátó adatai
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color='primary' variant='body1'>
                Szolgáltató típusa
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                required
                select
                id='billingVendor'
                name='billingVendor'
                value={values.billingVendor}
                onChange={handleChange}
                error={Boolean(errors.billingVendor)}
                helperText={touched.billingVendor && errors.billingVendor}
              >
                <MenuItem value={'SZAMLAZZ_HU'}>Számlázz.hu</MenuItem>
                <MenuItem value={'BILLINGO'}>Billingó</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Tooltip
                title={
                  "Másnéven Agent-kulcs, a szamlazz.hu-ra belépve a vezérlőpult felhasználói blokkjának aljára navigálva tudsz ilyet generálni!"
                }
              >
                <Typography variant="h4" className={classes.label}>
                  API kulcs <Info className={classes.info} />
                </Typography>
              </Tooltip>
              <TextField
                variant="standard"
                InputProps={{
                  ,
                }}
                className={classes.input}
                required
                id="APIKey"
                name="APIKey"
                fullWidth
                value={values.APIKey}
                onChange={(e) => {
                  handleChange(e);
                  setBillingData({
                    ...billingData,
                    APIKey: e.target.value,
                  });
                }}
                error={touched.APIKey && Boolean(errors.APIKey)}
                helperText={touched.APIKey && errors.APIKey}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Tooltip
                title={
                  "Másnéven számlatömb, ennek segítségével tudod azonosítani számláidat a szamlazz.hu-n!"
                }
              >
                <Typography variant="h4" className={classes.label}>
                  Számla előtag <Info className={classes.info} />
                </Typography>
              </Tooltip>
              <TextField
                variant="standard"
                InputProps={{
                  ,
                }}
                className={classes.input}
                required
                id="clientId"
                name="clientId"
                fullWidth
                value={values.clientId}
                onChange={(e) => {
                  handleChange(e);
                  setBillingData({
                    ...billingData,
                    clientId: e.target.value,
                  });
                }}
                error={touched.clientId && Boolean(errors.clientId)}
                helperText={touched.clientId && errors.clientId}
              />
            </Grid> */}
                    </Grid>
                    <Grid
                        item
                        container
                        style={{
                            margin: "2rem 0 0 0",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    name="submitted"
                                    id="submitted"
                                    checked={newsAccept}
                                    required
                                    onChange={(e) => {
                                        setNewsAccept(e.target.checked);
                                    }}
                                    error={!newsAccept}
                                />
                            }
                            label={
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                    className={classes.data}
                                >
                                    <p>Feliratkozom a hírlevélre</p>
                                </div>
                            }
                        />
                        <Grid item xs={6}>
                            <Button
                                onClick={() => {
                                    setBillingData({ ...values });
                                    handleBack();
                                }}
                                variant="outlined"
                                className={classes.button}
                            >
                                Vissza
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                onClick={handleSubmit}
                                variant="outlined"
                                className={classes.button}
                            >
                                Regisztrálok
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default BillingForm;
