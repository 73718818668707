import { getApi } from "api/axios";
import { useState, useEffect } from "react";

export const useAccountingLogics = () => {
  const [accountingData, setAccountingData] = useState();
  const [accountingDataLoading, setAccountingDataLoading] = useState(false);

  const fetchAccounting = async () => {
    try {
      setAccountingDataLoading(true);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/admin/orders/accounting${localStorage.getItem("accountChart")}`);
      if (res.status === 200) {
        setAccountingData(res.data._embedded.orderAccountingStatisticsDTOList);
      }
      setAccountingDataLoading(false);
    } catch (e) {
      console.log(e);
      setAccountingDataLoading(false);
    }
  };

  const [newShopData, setNewShopData] = useState();
  const [stateB, setStateB] = useState(() => {
    const series = [];
    const options = {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: "Pénz adatok",
        align: "left",
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["No data"],
      },
      yaxis: {
        title: {
          text: "Összeg",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    };

    return { series, options };
  });

  function parseDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return { year, month, day };
  }

  useEffect(() => {
    if (accountingData) {
      setNewShopData(() => {
        const newData = {};
        Object.keys(accountingData).forEach((key) => {
          const entry = accountingData[key];
          const parsedDate = parseDate(entry.createdAt);
          newData[key] = {
            ...entry,
            createdAt: `${parsedDate.year}-${parsedDate.month}-${parsedDate.day}`,
          };
        });

        const uniqueShopIds = [
          ...new Set(Object.values(newData).map((entry) => entry.shopId)),
        ];
        const uniqueDates = [
          ...new Set(Object.values(newData).map((entry) => entry.createdAt)),
        ];

        const combinations = [];
        uniqueShopIds.forEach((shopId) => {
          uniqueDates.forEach((date) => {
            combinations.push({ shopId, date });
          });
        });

        const existingCombinations = Object.values(newData).map((entry) => ({
          shopId: entry.shopId,
          date: entry.createdAt,
        }));
        const missingCombinations = combinations.filter(
          (combination) =>
            !existingCombinations.find(
              (existingCombination) =>
                existingCombination.shopId === combination.shopId &&
                existingCombination.date === combination.date
            )
        );

        const zeroData = missingCombinations.map((combination) => {
          const zeroEntry = {
            shopId: combination.shopId,
            createdAt: combination.date,
            grossAmount: 0,
            schoolName: "",
            className: "",
          };
          const existingEntry = Object.values(newData).find(
            (entry) => entry.shopId === combination.shopId
          );
          if (existingEntry) {
            zeroEntry.schoolName = existingEntry.schoolName;
            zeroEntry.className = existingEntry.className;
          }
          return zeroEntry;
        });

        const finalData = Object.values(newData)
          .concat(zeroData)
          .sort(
            (a, b) =>
              new Date(...a.createdAt.split("/").reverse()) -
              new Date(...b.createdAt.split("/").reverse())
          );

        return finalData;
      });
    }
  }, [accountingData]);

  useEffect(() => {
    if (newShopData) {
      setStateB(() => {
        const classDateToGrossAmountMapping = {};

        newShopData?.forEach((item) => {
          const key = `${item.schoolName} ${item.className} ${item.createdAt}`;
          classDateToGrossAmountMapping[key] =
            (classDateToGrossAmountMapping[key] || 0) + item.grossAmount;
        });

        const uniqueDates = [
          ...new Set(newShopData?.map((item) => item.createdAt)),
        ];
        const uniqueClasses = [
          ...new Set(
            newShopData?.map((item) => `${item.schoolName} ${item.className}`)
          ),
        ];

        const series = uniqueClasses?.map((classKey) => {
          const classSeriesData = uniqueDates?.map((date) => {
            const key = `${classKey} ${date}`;
            return classDateToGrossAmountMapping[key] || 0;
          });

          return {
            name: classKey,
            data: classSeriesData,
          };
        });

        const options = {
          chart: {
            type: "bar",
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          title: {
            text: "Pénz adatok",
            align: "left",
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: uniqueDates?.map((date) =>
              new Date(date).toLocaleDateString()
            ),
          },
          yaxis: {
            title: {
              text: "Összeg",
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          noData: {
            text: "Nincs elérhető adat",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          }
        };
        return { series, options };
      });
    }
  }, [newShopData]);

  return { fetchAccounting, accountingData, accountingDataLoading, stateB };
};
