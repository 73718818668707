import Grid from "@material-ui/core/Grid";

import {
  Button,
  CircularProgress, 
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@material-ui/core";
import ChangePasswordCard from "components/PhotoAdminComponents/Settings/Profile/ChangePasswordCard/ChangePasswordCard";
import ChangeEmailAddressCard from "components/PhotoAdminComponents/Settings/Profile/ChangeEmailAddressCard/ChangeEmailAddressCard";
import { useUserApi } from "pages/Register/hooks/useUserApi";
import { useState } from "react";
import ChangeContactNameCard from "components/PhotoAdminComponents/Settings/Profile/ChangeContactNameCard/ChangeContactNameCard";
import { useStyles } from "./styles";
import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";
import { errorToast, successToast } from "components/common/Toast/Toast";
import LoadIndicator from "components/common/LoadIndicator/LoadIndicator";
import ChangeBillingAddressCard from "components/PhotoAdminComponents/Settings/Profile/ChangeBillingAddressCard/ChangeBillingAddressCard";
import ChangeBillingNameCard from "components/PhotoAdminComponents/Settings/Profile/ChangeBillingNameCard/ChangeBillingNameCard";
import ChangeVatNumberCard from "components/PhotoAdminComponents/Settings/Profile/ChangeVatNumberCard/ChangeVatNumberCard";
import ChangeRegNumberCard from "components/PhotoAdminComponents/Settings/Profile/ChangeRegNumberCard/ChangeRegNumberCard";
import ChangeBankAccountNumberCard from "components/PhotoAdminComponents/Settings/Profile/ChangeBankAccountNumberCard/ChangeBankAccountNumberCard";
import ChangeContactAddressCard from "components/PhotoAdminComponents/Settings/Profile/ChangeContactAddressCard/ChangeContactAddressCard";
import ChangeContactPhoneCard from "components/PhotoAdminComponents/Settings/Profile/ChangeContactPhoneCard/ChangeContactPhoneCard";
import { useSelector } from "react-redux";

const Profile = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [inProgress, setInProgress] = useState();
  const {
    getCurrentUser,
    deleteCurrentUser,
    userError,
    putUserEmail,
    putUserPhone,
    putUserPassword,
    putUserBillingName,
    putUserAddress,
    putUserName,
    putUserVatNumber,
    putUserRegNumber,
    putUserBankAccountNumber,
  } = useUserApi();

  const [inactiveDialogOpen, setInactiveDialogOpen] = useState(false);

  const user = useSelector(
    (state) => state.nonPersistedReducers?.userSliceReducer?.currentUser
  );

  const [activeTab, setActiveTab] = useState(0);

  const showDialog = (status) => {
    if (status === 200 || userError) {
      successToast("Adatait sikeresen frissítette");
    } else {
      errorToast("Adatainak frissítése sikertelen");
    }
  };

  const putPasswordWithDialog = async ({ user, password }) => {
    setInProgress("password");
    const status = await putUserPassword({ user: user, password: password });
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };

  const putEmailWithDialog = async ({ user, email }) => {
    setInProgress("email");
    const status = await putUserEmail({ user: user, email: email });
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };
  const putPhoneWithDialog = async (body) => {
    setInProgress("phone");
    const status = await putUserPhone(body);
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };
  const putBillingAddressWithDialog = async (body) => {
    setInProgress("billingAddress");
    const status = await putUserAddress(body);
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };
  const putContactAddressWithDialog = async (body) => {
    setInProgress("contactAddress");
    const status = await putUserAddress(body);
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };

  const putBillingNameWithDialog = async (body) => {
    setInProgress("billingName");
    const status = await putUserBillingName(body);
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };
  const putVatNumberWithDialog = async (body) => {
    setInProgress("vatNumber");
    const status = await putUserVatNumber(body);
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };
  const putRegNumberWithDialog = async (body) => {
    setInProgress("regNumber");
    const status = await putUserRegNumber(body);
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };
  const putBankAccountNumberWithDialog = async (body) => {
    setInProgress("bankAccountNumber");
    const status = await putUserBankAccountNumber(body);
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };

  const putNameWithDialog = async ({ user, firstName, lastName }) => {
    setInProgress("name");
    const status = await putUserName({
      user: user,
      firstname: firstName,
      lastname: lastName,
    });
    getCurrentUser();
    showDialog(status);
    setInProgress(null);
  };
  const putInactiveWithDialog = async () => {
    setInProgress("delete");
    await deleteCurrentUser();
    setInactiveDialogOpen(false);
  };

  return (
    <Grid
      container
      alignItems="center"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography className={classes.title}>Profil beállítások</Typography>
      <Typography style={{ fontStyle: "oblique" }}>
        #Felhasználónév: {user?.username}
      </Typography>

      {user ? (
        <>
          {" "}
          <Tabs
            TabIndicatorProps={{
              style: { background: theme.palette.primary.main },
            }}
            value={activeTab}
          >
            <Tab
              selected={activeTab === 0}
              label="Kapcsolati adatok"
              onClick={() => {
                setActiveTab(0);
              }}
            />
            <Tab
              selected={activeTab === 1}
              label="Számlázási adatok"
              onClick={() => {
                setActiveTab(1);
              }}
            />
          </Tabs>
          <Grid
            container
            item
            xs={12}
            md={8}
            // style={{
            //   display: "flex",
            //   flexDirection: "column",
            //   alignItems: "center",
            // }}
          >
            {activeTab === 0 && (
              <>
                <Grid item xs={12} md={6}>
                  <ChangeContactNameCard
                    user={user}
                    putUserName={putNameWithDialog}
                    inProgress={inProgress}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ChangeContactPhoneCard
                    user={user}
                    putUserPhoneNumber={putPhoneWithDialog}
                    inProgress={inProgress}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ChangeEmailAddressCard
                    user={user}
                    putUserEmail={putEmailWithDialog}
                    inProgress={inProgress}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ChangePasswordCard
                    user={user}
                    putUserPassword={putPasswordWithDialog}
                    inProgress={inProgress}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ChangeContactAddressCard
                    user={user}
                    putUserContactAddress={putContactAddressWithDialog}
                    inProgress={inProgress}
                  />
                </Grid>
              </>
            )}
            {activeTab === 1 && (
              <>
                <Grid item xs={12} md={6}>
                  <ChangeBillingNameCard
                    user={user}
                    putUserBillingName={putBillingNameWithDialog}
                    inProgress={inProgress}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ChangeVatNumberCard
                    user={user}
                    putUserVatNumber={putVatNumberWithDialog}
                    inProgress={inProgress}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ChangeRegNumberCard
                    user={user}
                    putUserRegNumber={putRegNumberWithDialog}
                    inProgress={inProgress}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ChangeBankAccountNumberCard
                    user={user}
                    putUserBankAccountNumber={putBankAccountNumberWithDialog}
                    inProgress={inProgress}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ChangeBillingAddressCard
                    user={user}
                    putUserBillingAddress={putBillingAddressWithDialog}
                    inProgress={inProgress}
                  />
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "0.5rem",
              }}
            >
              <Button
                onClick={() => setInactiveDialogOpen(true)}
                variant="outlined"
                style={{
                  backgroundColor: theme.palette.error.dark,
                  fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
                  color: "whitesmoke",
                }}
              >
                {inProgress ? (
                  <>
                    Folyamatban
                    <CircularProgress
                      style={{ marginLeft: "0.5rem" }}
                      size={24}
                    />
                  </>
                ) : (
                  "Felhasználói fiók törlése"
                )}
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <LoadIndicator />
      )}
      <ConfirmDialog
        title={
          "Figyelem! Amennyiben törli fiókját, minden ehhez a fiókhoz tartozó adat, galéria és termék eltávolításra kerül oldalunkról, így azokat a későbbiekben már nem fogja tudni elérni! Biztosan törölni szeretné?"
        }
        open={inactiveDialogOpen}
        onOk={() => {
          putInactiveWithDialog();
          setInactiveDialogOpen(false);
        }}
        onClose={() => setInactiveDialogOpen(false)}
        functionButtonColor={theme.palette.primary.success}
        functionButtonText={"Igen"}
        closeButtonText={"Nem"}
        multipleButtons
      />
    </Grid>
  );
};

export default Profile;
