import { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useTheme } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
import {  
  Outlet,
  useNavigate,
} from "react-router-dom";
import { Divider, Paper, Tab, Tabs, Tooltip } from "@material-ui/core";

import { useStyles } from "./styles";
import HeaderData from "config/HeaderData";
import { useSelector } from "react-redux";

const Settings = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();

  const shopId = useSelector(
    (state) => state.nonPersistedReducers?.shopSliceReducer?.shopId
  );

  const user = useSelector(
    (state) => state.nonPersistedReducers?.userSliceReducer?.currentUser
  );

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grow in={true} timeout={1000}>
        <Paper className={classes.settingsWrapper}>
          <Tabs
            TabIndicatorProps={{
              style: { borderRight: 1, background: theme.palette.primary.main },
            }}
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            className={classes.tabs}
          >
            {HeaderData.options.site.map((option, idx) => (
              <>
                {option.to === "roles" &&
                user?.ownedShops[0]?.id !== shopId &&
                !user?.maintainedShops
                  ?.find((shop) => shop.shop.id === shopId)
                  ?.scopes?.includes("VIEW_SHOP") ? (
                  <Tooltip title="Ezt a felületet csak a megfelelő BOLT_MEGTEKINTÉSE jogosultság birtokában érheti el">
                    <Tab
                      disabled={option.disabled}
                      style={{
                        minWidth: "50px",
                        minHeight: "36px",
                        height: "36px",
                        fontFamily: `${theme.typography.main} !important`,
                      }}
                      onClick={() => {}}
                      icon={<Divider orientation="vertical" />}
                      label={`${option.name}`}
                      key={`${idx}_option_tab`}
                    />
                  </Tooltip>
                ) : option.to === "invoicing" &&
                  user?.ownedShops[0]?.id !== shopId &&
                  !user?.maintainedShops
                    ?.find((shop) => shop.shop.id === shopId)
                    ?.scopes?.includes("VIEW_INVOICE_VENDORS") ? (
                  <Tooltip title="Ezt a felületet csak a megfelelő SZÁMLÁZÓ_VENDOROK_MEGTEKINTÉSE jogosultság birtokában érheti el">
                    <Tab
                      disabled={option.disabled}
                      style={{
                        minWidth: "50px",
                        minHeight: "36px",
                        height: "36px",
                        fontFamily: `${theme.typography.main} !important`,
                      }}
                      onClick={() => {}}
                      icon={<Divider orientation="vertical" />}
                      label={`${option.name}`}
                      key={`${idx}_option_tab`}
                    />
                  </Tooltip>
                ) : option.to === "modifierinvoicevendors" ? (
                  <Tab
                    disabled={option.disabled}
                    style={{
                      minWidth: "50px",
                      minHeight: "36px",
                      height: "36px",
                      fontFamily: `${theme.typography.main} !important`,
                    }}
                    onClick={() => navigate(`${option.to}`)}
                    icon={<Divider orientation="vertical" />}
                    label={`${option.name}`}
                    key={`${idx}_option_tab`}
                  />
                ) : (
                  <Tab
                    disabled={option.disabled}
                    style={{
                      minWidth: "50px",
                      minHeight: "36px",
                      height: "36px",
                      fontFamily: `${theme.typography.main} !important`,
                    }}
                    onClick={() => navigate(`${option.to}`)}
                    icon={<Divider orientation="vertical" />}
                    label={`${option.name}`}
                    key={`${idx}_option_tab`}
                  />
                )}
              </>
            ))}
          </Tabs>
          <Outlet />
        </Paper>
      </Grow>
    </div>
  );
};

export default Settings;
