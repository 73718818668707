import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import background from "assets/images/background.jpg";

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
 

  box-sizing: border-box;

  height: 100%;
  background-size: cover;
  background-image: url(${background});
  background-repeat: no-repeat;
  margin-top -75px;
  @media (min-width: 900px) {
    margin-top: 0;
  }
`;

export const BackhomeBtn = styled.a`
  color: ${({ theme }) => theme.palette.primary.textPrimary};
  font-family: ${({ theme }) => theme.typography.main};
  opacity: 80%;
  text-decoration: none;
  position: absolute;
  left: 1rem;
  top: 1rem;
  &: hover {
    opacity: 100%;
  }
`;

export const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(200, 200, 200);
  background-color: rgba(0, 0, 0, 0.2);

  z-index: 100;
  backdrop-filter: blur(2px);
  display: grid;
  place-items: center;
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.img`
  width: 200px;
  height: 150px;
`;

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    backgroundColor: "transparent",
    borderColor: "transparent",
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "whitesmoke",
      borderRadius: "5px",
      borderTop: "4px solid",
      borderColor: "#1f1c16",
    },
  },
  grid: {
    width: "100%",
    padding: "1.5rem 2rem",
  },
  input: {
    width: "calc(100% - 1rem)",
    padding: "0.25rem 0.5rem",
    backgroundColor: "white",
    border: "1px solid rgb(230, 230, 230)",
    borderBottom: "1px solid rgb(200, 200, 200)",
    marginBottom: "0.5rem",
    "&::before": {
      border: "none",
    },
  },
  stepLabel: {
    borderRadius: "22px",
  },
  label: {
    fontWeight: 400,
    fontSize: "1rem",
    margin: "1rem 0 0 0",
  },
  button: {
    textTransform: "none",
    fontWeight: "400",
    border: "1px solid rgba(230, 230, 230, 0.5)",
    borderRadius: 0,
    backgroundColor: theme.palette.primary.backgroundSecondary,
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.primary.backgroundPrimary,
    },
  },
}));
