import * as yup from "yup";

const billingValidationSchema = yup.object({
  companyName: yup
    .string("Adja meg számlázási nevét")
    .max(50, "Legfeljebb 50 karakter hosszú számlázási nevet adjon meg")
    .required("Szükséges mező"),
  billingVatNumber: yup
    .string("Adja meg adószámát")
    .matches(/^[0-9]{8}[-][0-9][-][0-9]{2}$/, "Helyes formátum: 12345678-3-21")
    .required("Szükséges mező"),
  billingAccountNumber: yup
    .string("Adja meg bankszámlaszámát")
    .matches(
      /^[0-9]{8}[-][0-9]{8}[-][0-9]{8}$|^[0-9]{8}[-][0-9]{8}$|^[0-9]{16}$|^[0-9]{24}$/,
      "Helyes formátum: 12345678-12345678 / 12345678-12345678-12345678"
    )
    .max(26, "Helyes formátum: 12345678-12345678 / 12345678-12345678-12345678")
    .required("Szükséges mező"),
  billingRegisterNumber: yup
    .string("Adja meg nyilvántartási számát")
    .max(8, "Kérjük adja meg 8 jegyű nyilvántartási számát")
    .required("Szükséges mező"),
  billingCountry: yup
    .string("Adja meg országát")
    .min(1, "Adja meg országát")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  billingCity: yup
    .string("Adja meg városát")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  billingZipCode: yup
    .number("Adja meg városának irányítószámát")
    .typeError("Kizárólag számok megengedettek")
    .min(999, "Irányítószáma egy 4 jegyű szám")
    .max(9999, "Irányítószáma egy 4 jegyű szám")
    .required("Szükséges mező"),
  billingStreetAndNumber: yup
    .string("Adja meg a közterület nevét")
    .min(1, "Adja meg a közterület nevét")
    .max(40, "Nem lehet hosszabb 40 karakternél")
    .required("Szükséges mező"),
  // billingVendor: yup
  //   .string("Válassza ki a számlázási szolgáltatót")
  //   .required("Szükséges mező"),
  // APIKey: yup.string("Adja meg API kulcsát").required("Szükséges mező"),
  // clientId: yup
  //   .string("Adja meg a kívánt számla előtagját")
  //   .required("Szükséges mező"),
});

export default billingValidationSchema;
