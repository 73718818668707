import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { Dialog, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import {
  DialogContentWrapper,
  Transition,
  Title,
  ButtonsWrapper,
  useStyles,
  Title2,
} from "./styles";

const ConfirmDialog = ({
  open,
  onOk,
  onClose,
  title,
  text,
  text2,
  disabled,
  functionButtonText,
  functionButtonColor,
  closeButtonText,
  multipleButtons,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      className={classes.dialog}
      onClick={(e) => e.stopPropagation()}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContentWrapper onClick={(e) => e.stopPropagation()}>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText component={"div"}>
            <Title>{title}</Title>
            <Title2>{text}</Title2>
            <Title2>{text2}</Title2>
          </DialogContentText>
          <ButtonsWrapper>
            {multipleButtons && (
              <Button style={{ color: functionButtonColor }} onClick={onOk}>
                {functionButtonText}
              </Button>
            )}
            <Button disabled={disabled} onClick={onClose}>
              {closeButtonText}
            </Button>
          </ButtonsWrapper>
        </DialogContent>
      </DialogContentWrapper>
    </Dialog>
  );
};

export default ConfirmDialog;
