const queryOptions = [
  {
    name: "orderId",
    displayName: "Azonosító",
    type: "string",
    used: false,
    value: "",
  },
  {
    name: "customerName",
    displayName: "Ügyfél neve",
    type: "string",
    used: false,
    value: "",
  },
  {
    name: "deliveryTarget",
    displayName: "Kiszállítási cím",
    type: "deliveryTarget",
    used: false,
    value: "",
  },
  {
    name: "createdAt",
    displayName: "Rendelés dátuma",
    type: "date",
    used: false,
    value: "",
  },
  {
    name: "expiration",
    displayName: "Lejárat dátuma",
    type: "date",
    used: false,
    value: "",
  },
  {
    name: "statuses",
    displayName: "Státusz",
    type: "status",
    values: [
      "PENDING",
      "PAID",
      "INVOICED",
      "SHIPPING",
      "DONE",
      "REJECTED_DELIVERY",
      "REJECTED_INVOICE",
      "REJECTED_PAYMENT",
    ],
    displayedValues: [
      "FÜGGŐBEN",
      "FIZETVE",
      "SZÁMLÁZVA",
      "KISZÁLLÍTÁS ALATT",
      "TELJESÍTVE",
      "SIKERTELEN KÉZBESÍTÉS",
      "SIKERTELEN SZÁMLÁZÁS",
      "SIKERTELEN FIZETÉS",
    ],
    used: false,
    value: "",
  },
  {
    name: "invoiceStates",
    displayName: "Számlázási státusz",
    type: "select",
    values: [
      'NO_INVOICE',
      'PARTIAL_INVOICE',
      'FAILED_INVOICE',
      'INVOICED'
    ],
    displayedValues: [
      "NINCS SZÁMLÁZVA",
      "RÉSZBEN SZÁMLÁZVA",
      "SIKERTELENÜL SZÁMLÁZVA",
      "SIKERESEN SZÁMLÁZVA",
    ],
    used: false,
    value: "",
  },
  {
    name: "sort",
    displayName: "Rendezési szempont",
    type: "key-value",
    keys: [
      { name: "id", displayName: "Azonosító" },
      { name: "billingInfo.customerName", displayName: "Ügyfél neve" },
      { name: "status", displayName: "Státusz" },
      { name: "deliveryTarget", displayName: "Kiszállítási cím" },
    ],
    values: [
      { name: "asc", displayName: "Növekvő" },
      { name: "desc", displayName: "Csökkenő" },
    ],
    used: false,
    value: "",
  },
  {
    name: "pagination",
    displayName: "",
    type: "pagination",
    used: true,
    value: "",
  },
];

export default queryOptions;
