import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Reports from '../../../components/SuperAdminComponents/Dashboard/Reports/Reports';
import Chart from '../../../components/SuperAdminComponents/Dashboard/Chart/Chart';
import Deposits from '../../../components/SuperAdminComponents/Dashboard/Deposits/Deposits';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import DeliveryVendors from '../../../components/SuperAdminComponents/Dashboard/DeliveryVendors/DeliveryVendors';
import Accounting from 'components/SuperAdminComponents/Dashboard/Accounting/Accounting';
import ReportChart from 'components/SuperAdminComponents/Dashboard/ReportChart/ReportChart';

const Dashboard = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label='breadcrumb' className={classes.breadcrumb}>
        <Typography color='inherit' variant='body1'>
          Vezérlőpult
        </Typography>
      </Breadcrumbs>
      <div>
        <CssBaseline />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth={"xl"} className={classes.container}>
            <Grid container spacing={3}>
              {/* Chart 
              <Grid item xs={12} md={8} lg={9}>
                <Paper className={fixedHeightPaper}>
                  <Chart />
                </Paper>
              </Grid>
              
              <Grid item xs={12} md={4} lg={3}>
                <Paper className={fixedHeightPaper}>
                  <Deposits />
                </Paper>
              </Grid>
              */}
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Reports />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Accounting />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <ReportChart />
                </Paper>
              </Grid>
              {/*
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <DeliveryVendors />
                  </Paper>
                </Grid>
              */}
            </Grid>
          </Container>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
