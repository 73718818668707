/** @format */

import { useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useNavigate } from "react-router-dom";
import HeaderData from "config/HeaderData";
import AuthService from "services/AuthService";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Menu, MenuItem, Tooltip } from "@material-ui/core";
import StoreIcon from "@mui/icons-material/Store";
import { useEffect, useState } from "react";

const NavbarMenu = ({ match, user, ownShop }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const navigate = useNavigate();

    const shopId = useSelector(
        (state) => state.nonPersistedReducers?.shopSliceReducer?.shopId
    );

    const [profileAnchor, setProfileAnchor] = useState(null);
    const [shopAnchor, setShopAnchor] = useState(null);

    const handleProfileMenu = (e) => {
        setProfileAnchor(e.currentTarget);
    };
    const handleShopMenu = (e) => {
        setShopAnchor(e.currentTarget);
    };

    const handleClose = () => {
        setShopAnchor(null);
        setProfileAnchor(null);
    };

    const handleLogout = () => {
        //dispatch(logout());
        AuthService.doLogout();
    };

    return (
        <List component="nav" className={classes.bigList}>
            {AuthService.isLoggedIn() && (
                <>
                    {(user?.groups?.find((group) => group === "dt_admin") ||
                        user?.groups?.find(
                            (group) => group === "printer_admin"
                        )) && (
                        <Tooltip title={"Kijelentkezés"}>
                            <ListItem
                                button
                                onClick={(e) => {
                                    handleLogout();
                                }}
                                className={classes.listItem}
                            >
                                {HeaderData.logoutOption.icon}
                            </ListItem>
                        </Tooltip>
                    )}

                    {user?.groups?.find((group) => group === "users") && (
                        <>
                            <Tooltip
                                title={
                                    user?.ownedShops?.length !== 1
                                        ? "Boltok kezelése"
                                        : "Bolt kezelése"
                                }
                            >
                                <ListItem
                                    button
                                    onClick={(e) => {
                                        handleShopMenu(e);

                                        e.stopPropagation();
                                    }}
                                    className={classes.listItem}
                                >
                                    <StoreIcon
                                        classes={{
                                            primary: classes.bigListItemText,
                                        }}
                                    />
                                </ListItem>
                            </Tooltip>
                            <Menu
                                id="shop-appbar"
                                anchorEl={shopAnchor}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(shopAnchor)}
                                onClose={handleClose}
                            >
                                <MenuItem
                                    onClick={() => {
                                        navigate(`/shops`);
                                        handleClose();
                                    }}
                                    disabled={
                                        user?.ownedShops?.length === 1 &&
                                        user?.maintainedShops?.length === 0
                                    }
                                >
                                    Boltok
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        navigate(
                                            `/shops/${shopId}/administration/orders`
                                        );
                                        handleClose();
                                    }}
                                    disabled={shopId === -1}
                                >
                                    Bolt adminisztráció
                                </MenuItem>
                                {ownShop ? (
                                    <MenuItem
                                        onClick={() => {
                                            navigate(
                                                `/shops/${shopId}/settings/roles`
                                            );
                                            handleClose();
                                        }}
                                    >
                                        Bolt beállítások
                                    </MenuItem>
                                ) : shopId !== -1 &&
                                  !ownShop &&
                                  user?.maintainedShops
                                      ?.find((shop) => shop.shop.id === shopId)
                                      ?.scopes?.includes("VIEW_SHOP") ? (
                                    <MenuItem
                                        onClick={() => {
                                            navigate(
                                                `/shops/${shopId}/settings/roles`
                                            );
                                            handleClose();
                                        }}
                                    >
                                        Bolt beállítások
                                    </MenuItem>
                                ) : shopId !== -1 &&
                                  !ownShop &&
                                  !user?.maintainedShops
                                      ?.find((shop) => shop.shop.id === shopId)
                                      ?.scopes?.includes("VIEW_SHOP") &&
                                  user?.maintainedShops
                                      ?.find((shop) => shop.shop.id === shopId)
                                      ?.scopes?.includes(
                                          "VIEW_INVOICE_VENDORS"
                                      ) ? (
                                    <MenuItem
                                        onClick={() => {
                                            navigate(
                                                `/shops/${shopId}/settings/invoicing`
                                            );
                                            handleClose();
                                        }}
                                    >
                                        Bolt beállítások
                                    </MenuItem>
                                ) : shopId === -1 ? (
                                    <MenuItem onClick={() => {}} disabled>
                                        Bolt beállítások
                                    </MenuItem>
                                ) : (
                                    <Tooltip
                                        title={
                                            "A bolt beállításait csak a megfelelő jogosultságok birtokában érheti el"
                                        }
                                    >
                                        <MenuItem
                                            onClick={() => {}}
                                            style={{
                                                color: "rgba(144,144,144, 0.8)",
                                            }}
                                        >
                                            Bolt beállítások
                                        </MenuItem>
                                    </Tooltip>
                                )}
                            </Menu>
                            <Tooltip title={"Felhasználói fiók"}>
                                <ListItem
                                    button
                                    onClick={(e) => {
                                        handleProfileMenu(e);
                                    }}
                                    className={classes.listItem}
                                >
                                    <AccountCircleIcon
                                        classes={{
                                            primary: classes.bigListItemText,
                                        }}
                                    />
                                </ListItem>
                            </Tooltip>
                            <Menu
                                id="menu-appbar"
                                anchorEl={profileAnchor}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(profileAnchor)}
                                onClose={handleClose}
                            >
                                <MenuItem
                                    onClick={() => {
                                        navigate(`/settings/profile`);
                                        handleClose();
                                    }}
                                >
                                    Profil beállítások
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleLogout();
                                        handleClose();
                                    }}
                                >
                                    Kijelentkezés
                                </MenuItem>
                            </Menu>{" "}
                        </>
                    )}
                </>
            )}
        </List>
    );
};

export default NavbarMenu;
