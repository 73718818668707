import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const useStyles = makeStyles((theme) => ({
  formPart: {
    width: "100%",
  },
  grid: {
    width: "100%",
    padding: "1.5rem 2rem",
  },
  input: {
    width: "calc(100% - 1rem)",
    padding: "0.25rem 0.5rem",
    backgroundColor: "white",
    border: "1px solid rgb(230, 230, 230)",
    borderBottom: "1px solid rgb(200, 200, 200)",
    marginBottom: "0.5rem",
    "&::before": {
      border: "none",
    },
  },
  stepLabel: {
    borderRadius: "22px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  subtitle: {
    fontWeight: 600,
    fontSize: "1rem",
    margin: "1rem 0 0 0",
  },
  info: {
    fontSize: "15px !important",
    color: "grey",
  },
  label: {
    fontWeight: 400,
    fontSize: "1rem",
    margin: "1rem 0 0 0",
  },
  button: {
    textTransform: "none",
    fontWeight: "400",
    border: "1px solid rgba(230, 230, 230, 0.5)",
    borderRadius: 0,
    backgroundColor: theme.palette.primary.backgroundSecondary,
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.primary.backgroundPrimary,
    },
  },
}));
