import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Edit from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import { useStyles } from "./styles";
import SettingsCard from "components/PhotoAdminComponents/Settings/Card/SettingsCard";
import ActionButton from "components/common/ActionButton/ActionButton";
import { Form, Formik } from "formik";
import { useRef } from "react";
import { CircularProgress } from "@material-ui/core";
import regNumberChangeValidationSchema from "validation/UserSettings/RegNumberChangeValidationSchema";

const ChangeRegNumberCard = ({ user, putUserRegNumber, inProgress }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const formikRef = useRef(null);

  const [regNumberData, setRegNumberData] = useState({
    registrationNumber: "",
  });

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.values.registrationNumber =
        regNumberData?.registrationNumber;
    }
  }, [user, formikRef]);

  useEffect(() => {
    if (isEditing) {
      setRegNumberData({ registrationNumber: "" });
    } else {
      setRegNumberData({
        registrationNumber: user?.billingInfo?.registrationNumber,
      });
    }
  }, [user, isEditing]);

  return (
    <SettingsCard
      title={"Nyilvántartási szám"}
      actionButton={
        <ActionButton
          icon={<Edit color="primary" />}
          clickHandler={toggleIsEditing}
        />
      }
    >
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          registrationNumber: "",
        }}
        validationSchema={regNumberChangeValidationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          await putUserRegNumber({
            ...user,
            billingInfo: {
              ...user?.billingInfo,
              registrationNumber: values.registrationNumber,
            },
          });
          setIsEditing(false);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <Form style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  variant="outlined"
                  fullWidth
                  required
                  label={"Nyilvántartási szám"}
                  id="registrationNumber"
                  name="registrationNumber"
                  value={
                    isEditing
                      ? values.registrationNumber
                      : regNumberData?.registrationNumber
                  }
                  onChange={handleChange}
                  error={
                    touched.registrationNumber &&
                    Boolean(errors.registrationNumber)
                  }
                  helperText={
                    touched.registrationNumber && errors.registrationNumber
                  }
                />
              </Grid>{" "}
            </Grid>
            <Grid
              item
              container
              style={{
                margin: "2rem 0 0 0",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Grid item xs={12}>
                <Button
                  disabled={!isEditing}
                  type="submit"
                  fullWidth
                  onClick={handleSubmit}
                  variant="outlined"
                  className={classes.button}
                >
                  {inProgress === "regNumber" ? (
                    <>
                      Folyamatban
                      <CircularProgress
                        style={{ marginLeft: "0.5rem" }}
                        size={24}
                      />
                    </>
                  ) : (
                    "Nyilvántartási szám frissítése"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </SettingsCard>
  );
};

export default ChangeRegNumberCard;
