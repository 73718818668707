import * as yup from "yup";

const regNumberChangeValidationSchema = yup.object({
  registrationNumber: yup
    .string("Adja meg nyilvántartási számát")
    .max(8, "Kérjük adja meg 8 jegyű nyilvántartási számát")
    .required("Szükséges mező"),
});

export default regNumberChangeValidationSchema;
