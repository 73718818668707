import { useState, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import {
  useStyles,
  Wrapper,
  HeaderContent,
  Logo,
  FormWrapper,
  LoadingWrapper,
  BackhomeBtn,
} from "./styles";
import logo from "assets/icons/logo.svg";

import ContactForm from "components/RegisterComponents/ContactForm/ContactForm";
import BillingForm from "components/RegisterComponents/BillingForm/BillingForm";
import LoadIndicator from "components/common/LoadIndicator/LoadIndicator";
import AuthService from "services/AuthService";
import CheckIcon from "components/common/CheckIcon/CheckIcon";
import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";

import { useUserApi } from "./hooks/useUserApi";

const Register = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [step, setStep] = useState(0);
  const [contactData, setContactData] = useState({
    contactCountry: "Magyarország",
  });
  const [billingData, setBillingData] = useState({
    billingVendor: "SZAMLAZZ_HU",
  });
  const [wantShop, setWantShop] = useState(false);
  const [isSure, setIsSure] = useState(false);
  const [newsAccept, setNewsAccept] = useState(false);
  const [contactSubmitted, setContactSubmitted] = useState(false);
  const [billingSubmitted, setBillingSubmitted] = useState(false);

  const [open, setOpen] = useState(false);

  const [errorOpen, setErrorOpen] = useState(false);

  const {
    postUser,
    userLoading,
    userError,
    redirectOnSuccess,
    subscribeNewsletter,
  } = useUserApi();

  const resetData = () => {
    setContactData({ contactCountry: "Magyarország" });
    setBillingData({ billingVendor: "SZAMLAZZ_HU" });
    setStep(0);
    setErrorOpen(false);
    setIsSure(false);
    setWantShop(false);
  };

  const handleRedirect = () => {
    AuthService.doLogin();
  };
  const handleNext = () => setStep(step + 1);
  const handleBack = () => {
    setStep(step - 1);
    setContactSubmitted(false);
    setIsSure(false);
  };

  const handleRegister = async () => {
    if (isSure) {
      await postUser({ ...contactData, resetData });
      setIsSure(false);
    } else {
      await postUser({
        ...contactData,
        ...billingData,
        resetData,
      });
      setIsSure(false);
    }
  };

  // useEffect(() => {
  //   if (user) {
  //     createShop({
  //       body: {
  //         name: `${contactData?.username}_shop`,
  //         aboutMeDescription: "About me!",
  //         footerDescription: "Footer me!",
  //         owner: { username: contactData?.username },
  //       },
  //     });
  //   }
  // }, [user]);

  useEffect(() => {
    if (userError) {
      setErrorOpen(true);
    }
    console.log(userError);
  }, [userError]);

  useEffect(() => {
    if (!wantShop) {
      if (contactData && contactSubmitted && isSure) {
        handleRegister();
        setContactSubmitted(false);
      }
    } else {
      if (contactData && billingData && billingSubmitted && contactSubmitted) {
        handleRegister();
        setContactSubmitted(false);
        setBillingSubmitted(false);
      }
    }
  }, [
    contactData,
    billingData,
    billingSubmitted,
    contactSubmitted,
    wantShop,
    isSure,
  ]);

  return (
    <Wrapper>
      <BackhomeBtn href="https://iskolaevkonyv.hu">
        <ArrowBackIosNewIcon
          style={{ fontSize: "0.8rem", marginRight: "0.5rem" }}
        />
        Vissza a főoldalra
      </BackhomeBtn>
      {userLoading && (
        <LoadingWrapper>
          <LoadIndicator color={theme.palette.primary.light} height={300} />
        </LoadingWrapper>
      )}

      <Dialog open={errorOpen}>
        <DialogTitle>
          <Typography variant="h4" color="primary">
            Regisztráció sikertelen!
          </Typography>
        </DialogTitle>
        <DialogContent>
          {userError === "Email already registered" ? (
            <Typography variant="body2" color="primary">
              Ezzel az email címmel már regisztráltak, ellenőrizze adatait!
            </Typography>
          ) : userError === "Username already registered" ? (
            <Typography variant="body2" color="primary">
              Ez a felhasználónév már foglalt, kérjük használjon másikat!
            </Typography>
          ) : (
            <Typography variant="body2" color="primary">
              Hiba történt, regisztráció sikertelen. Kérjük próbálja újra
              később.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            onClick={() => {
              // resetData()
              setErrorOpen(false);
              setStep(0);
            }}
            variant="outlined"
            className={classes.button}
          >
            Mégse
          </Button>
          <Button
            type="submit"
            onClick={() => handleRegister({ ...contactData, ...billingData })}
            variant="outlined"
            className={classes.button}
          >
            Újra
          </Button>
        </DialogActions>
      </Dialog>
      <FormWrapper>
        <HeaderContent>
          <a href="https://iskolaevkonyv.hu">
            <Logo src={logo} />
          </a>

          <Typography
            variant="h1"
            style={{
              color: "whitesmoke",
              fontSize: "29px",
              textTransform: "uppercase",
              letterSpacing: "3px",
              lineHeight: "1.2em",
              margin: "0 0 2rem 0",
              padding: "0",
              whiteSpace: "normal",
              textAlign: "center",
              fontFamily: '"Open Sans",Helvetica,Arial,sans-serif',
            }}
          >
            ÜDVÖZLÜNK AZ ISKOLAÉVKÖNYV OLDALÁN
          </Typography>
        </HeaderContent>
        {redirectOnSuccess ? (
          <Grid
            container
            alignContent="center"
            className={`${classes.grid} ${classes.form}`}
          >
            <Grid item xs={12} style={{ height: "120px", width: "100%" }}>
              <CheckIcon
                position={"relative"}
                right={0}
                bottom={0}
                size={50}
                speedy
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                style={{
                  color: "black",
                  fontWeight: "300",
                  fontSize: "24px",
                  lineHeight: "1.2em",
                  margin: "0.5rem",
                  whiteSpace: "normal",
                  textAlign: "center",
                }}
              >
                Sikeres regisztráció
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography style={{ margin: "2rem 0 1rem" }}>
                Sikeresen regisztrált oldalunkra! A véglegesítéshez aktiválja
                fiókját az Ön által megadott email címre kiküldött aktiváló link
                segítségével! Kattintson a Bejelentkezés gombra hogy
                átirányítsuk a bejelentkező felületre!
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{
                margin: "2rem 0 0 0",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Grid item xs={6}>
                <Button
                  onClick={() => handleRedirect()}
                  variant="outlined"
                  className={classes.button}
                >
                  Bejelentkezés
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            alignContent="center"
            className={`${classes.grid} ${classes.form}`}
          >
            <Grid item xs={12}>
              <Typography
                variant="h1"
                style={{
                  color: "black",
                  fontWeight: "300",
                  fontSize: "24px",
                  lineHeight: "1.2em",
                  margin: "0.5rem",
                  whiteSpace: "normal",
                  textAlign: "center",
                }}
              >
                Regisztráljon új fiókot
              </Typography>
            </Grid>
            <Grid container>
              <Stepper style={{ background: "transparent" }} activeStep={step}>
                <Step key={`contact_step`}>
                  <StepLabel className={classes.stepLabel}>
                    Kapcsolattartói adatok
                  </StepLabel>
                </Step>

                <Step
                  key={`billing_step`}
                  style={{
                    opacity: wantShop ? 1 : 0,
                    transition: "opacity 300ms",
                  }}
                >
                  <StepLabel className={classes.stepLabel}>
                    Számlázási adatok
                  </StepLabel>
                </Step>
              </Stepper>
            </Grid>
            {step === 0 && (
              <ContactForm
                contactData={contactData}
                setContactData={setContactData}
                handleNext={handleNext}
                setOpen={setOpen}
                setSubmitted={setContactSubmitted}
                wantShop={wantShop}
                setWantShop={setWantShop}
                setIsSure={setIsSure}
                newsAccept={newsAccept}
                setNewsAccept={setNewsAccept}
                subscribeNewsletter={subscribeNewsletter}
              />
            )}
            {step === 1 && (
              <BillingForm
                setBillingData={setBillingData}
                billingData={billingData}
                contactData={contactData}
                handleBack={handleBack}
                setSubmitted={setBillingSubmitted}
                newsAccept={newsAccept}
                setNewsAccept={setNewsAccept}
                subscribeNewsletter={subscribeNewsletter}
              />
            )}
          </Grid>
        )}
      </FormWrapper>
      <ConfirmDialog
        open={open}
        multipleButtons
        functionButtonColor={theme.palette.primary.success}
        functionButtonText={"Igen"}
        closeButtonText={"Mégsem"}
        title={`Biztosan bolt nélkül akarsz fiókot regisztrálni? Később amennyiben mégis szeretnél boltot létrehozni, lehetőséged lesz rá, a bolt ikonnal jelzett menüpont alatti "Boltok" oldalon!`}
        onOk={() => {
          setOpen(false);
          setIsSure(true);
        }}
        onClose={() => {
          setOpen(false);
          setIsSure(false);
        }}
      />
    </Wrapper>
  );
};

export default Register;
