import { Fragment, useEffect } from "react"
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import Title from '../Title/Title';
import { useStyles } from "./styles"
import { useGetDeposits } from "./hooks/useGetDeposits"

function preventDefault(event) {
  event.preventDefault();
}

const Deposits = (props) => {
  const classes = useStyles();

  const {
    fetchDeposits,
    deposits
  } = useGetDeposits()

  const date = new Date()
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  useEffect(() => {
    fetchDeposits()
  }, [])


  return (
    <Fragment>
      <Title>Jelenlegi egyenleg</Title>
      <Typography component="p" variant="h4" className={classes.root}>
        {deposits} HUF
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        {date.toLocaleDateString('hu-HU', options)}
      </Typography>
      <div>
        <Link className={classes.amount} color="primary" to="#" onClick={preventDefault}>
          Egyenleg megtekintése
        </Link>
      </div>
    </Fragment>
  );
}

export default Deposits 