/** @format */

import { useGetQueryString } from "hooks/useGetQueryString";
import React from "react";
import { useState } from "react";
import QueryOptions from "./config/QueryOptions";
import PrinterQueryOptions from "./config/PrinterQueryOptions";
import GroupQueryOptions from "./config/GroupQueryOptions";
import ReportingQueryOptions from "./config/ReportingQueryOptions";
import AdminQueryOptions from "./config/AdminQueryOptions";
import QueryParam from "./QueryParam/QueryParam";
import AddIcon from "@mui/icons-material/Add";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
    Wrapper,
    OptionsWrapper,
    ActionsWrapper,
    AddWrapper,
    TitleWrapper,
    SearchWrapper,
    Option,
    useStyles,
} from "./styles";
import {
    Button,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import { useEffect } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import RefreshIcon from "@mui/icons-material/Refresh";
const ChartQueryBuilder = ({
    children,
    siteType,
    chartName,
    accountingData,
    refetch
}) => {
    const theme = useTheme();
    const [search, setSearch] = useState(localStorage.getItem(chartName))

    const resetQueryString = () => {
        localStorage.setItem(chartName, "");
        setSearch(localStorage.getItem(chartName));
        refetch();
        setOptions(
            getInitialOptions({
            options:
                siteType === "printer"
                    ? PrinterQueryOptions
                    : siteType === "group"
                    ? GroupQueryOptions
                    : siteType === "reporting"
                    ? ReportingQueryOptions
                    : siteType === "admin"
                    ? AdminQueryOptions
                    : QueryOptions,
        }))
      };

    const classes = useStyles(theme);
    const { getQueryString } = useGetQueryString();

    //TODO check value validity
    const getInitialOptions = ({ options }) => {
        return options?.map((o) => {
            return { ...o, used: false, value: "" };
        });
    };

    const [options, setOptions] = useState(
        getInitialOptions({
            options:
                siteType === "printer"
                    ? PrinterQueryOptions
                    : siteType === "group"
                    ? GroupQueryOptions
                    : siteType === "reporting"
                    ? ReportingQueryOptions
                    : siteType === "admin"
                    ? AdminQueryOptions
                    : QueryOptions,
        })
    );
    const [currentQueryString, setCurrentQueryString] = useState(localStorage.getItem(chartName));
    const [isAddDisabled, setIsAddDisabled] = useState(false);
    const [isAddOptionOpen, setIsAddOptionOpen] = useState(false);

    const toggleIsAddOptionOpen = () => setIsAddOptionOpen(!isAddOptionOpen);

    useEffect(() => {
        setCurrentQueryString(getQueryString(options));
    }, [options]);

    useEffect(() => {
        if (search !== currentQueryString) {
            if (siteType === "group" || siteType === "admin") {
                localStorage.setItem(`${chartName}`,currentQueryString)
            } else if (siteType === "printer") {
                localStorage.setItem(`${chartName}`,currentQueryString)
            } else if (siteType === "reporting") {
                localStorage.setItem(`${chartName}`,currentQueryString)
            } else {
                localStorage.setItem(`${chartName}`,currentQueryString)
            }
        }
        refetch();
        setSearch(localStorage.getItem(chartName));
    }, [currentQueryString]);

    const setOption = (option) => {
        setOptions(
            options.map((currentOption) => {
                if (currentOption.name === option.name) {
                    return option;
                } else {
                    return currentOption;
                }
            })
        );
    };

    const disableOption = (option) => {
        if (siteType === "reporting") {
            setOptions(
                options.map((currentOption) => {
                    if (currentOption.name === option.name) {
                        currentOption.used = false;
                        currentOption.value =
                            getQueryString(localStorage.getItem(chartName)).slice(1);
                    }
                    return currentOption;
                })
            );
        } else {
            setOptions(
                options.map((currentOption) => {
                    if (currentOption.name === option.name) {
                        currentOption.used = false;
                        currentOption.value = "";
                    }
                    return currentOption;
                })
            );
        }
    };
    const enableOption = (name) => {
        setOptions(
            options.map((currentOption) => {
                if (currentOption.name === name) {
                    currentOption.used = true;
                }
                return currentOption;
            })
        );
        setIsAddOptionOpen(false);
    };

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Wrapper
                className={
                    siteType === "printer"
                        ? classes.printerWrapper
                        : siteType === "group" || siteType === "admin"
                        ? classes.groupWrapper
                        : classes.normalWrapper
                }
            >
                <SearchWrapper
                    backgroundColor={
                        siteType === "group"
                            ? "transparent"
                            : theme.palette.primary.backgroundSecondary
                    }
                >
                    <TitleWrapper>
                        <Typography
                            component="div"
                            variant="h2"
                            style={{
                                color:
                                    siteType === "group"
                                        ? theme.palette.primary
                                              .backgroundSecondary
                                        : theme.palette.primary.contrastText,
                                fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
                                fontSize: "2.5rem",
                            }}
                        >
                            Keresés
                        </Typography>
                    </TitleWrapper>
                    {options.map(
                        (option) =>
                            option.used &&
                            option.type !== "pagination" && (
                                <QueryParam
                                    key={`${option.name}_queryparam`}
                                    option={{ ...option }}
                                    setOption={setOption}
                                    disableOption={() => disableOption(option)}
                                    setIsAddDisabled={setIsAddDisabled}
                                    accountingData={accountingData}
                                />
                            )
                    )}
                    <AddWrapper>
                        <Tooltip title={"Keresési szűrő hozzáadása"}>
                            <Button
                                variant="outlined"
                                style={{
                                    backgroundColor: isAddOptionOpen
                                        ? theme.palette.primary.main
                                        : theme.palette.primary
                                              .backgroundContent,
                                    color: isAddOptionOpen
                                        ? theme.palette.primary
                                              .backgroundContent
                                        : theme.palette.primary.main,
                                    border: `2px solid ${theme.palette.primary.backgroundPrimary}`,
                                }}
                                onClick={toggleIsAddOptionOpen}
                                disabled={isAddDisabled}
                            >
                                {isAddOptionOpen ? <RemoveIcon /> : <AddIcon />}
                                Szűrő hozzáadása
                            </Button>
                        </Tooltip>
                        <Tooltip title={"Keresés alaphelyzetbe állítása"}>
                            <Button
                                style={{
                                    backgroundColor:
                                        theme.palette.primary.backgroundContent,
                                    color: theme.palette.primary.main,
                                    margin: 0,
                                    border: `2px solid ${theme.palette.primary.backgroundPrimary}`,
                                }}
                                variant="outlined"
                                onClick={() => {
                                    resetQueryString();
                                }}
                            >
                                <RestartAltIcon
                                    style={{ fontSize: "1.5rem" }}
                                />
                                Szűrők törlése
                            </Button>
                        </Tooltip>
                        <Tooltip title={"Tételek frissítése"}>
                            <Button
                                variant="outlined"
                                style={{
                                    backgroundColor:
                                        theme.palette.primary.backgroundContent,
                                    color: theme.palette.primary.main,
                                    marginTop: 0,
                                    border: `2px solid ${theme.palette.primary.backgroundPrimary}`,
                                    transition: "background 300ms",
                                    // opacity: !deliveries?.find(
                                    //   (delivery) => delivery.selected
                                    // )
                                    //   ? 0.3
                                    //   : 1,
                                }}
                                onClick={() => refetch()}
                            >
                                <RefreshIcon style={{ fontSize: "1.5rem" }} />
                                {siteType === "printer" ||
                                siteType === "group" ||
                                siteType === "admin"
                                    ? "Iskolák frissítése"
                                    : siteType === "reporting"
                                    ? "Jelentések frissítése"
                                    : "Rendelések frissítése"}
                            </Button>
                        </Tooltip>
                        {isAddOptionOpen && (
                            <OptionsWrapper
                                backgroundColor={
                                    theme.palette.primary.backgroundContent
                                }
                                borderColor={theme.palette.primary.main}
                            >
                                {options.map(
                                    (option) =>
                                        !option.used &&
                                        option.type !== "pagination" && (
                                            <Option
                                                backgroundColor={
                                                    theme.palette.primary
                                                        .backgroundContent
                                                }
                                                color={
                                                    theme.palette.primary.main
                                                }
                                                fontFamily={
                                                    theme.typography.main
                                                }
                                                key={`${option.name}_option`}
                                                onClick={(e) =>
                                                    enableOption(option.name)
                                                }
                                            >
                                                {option.displayName}
                                            </Option>
                                        )
                                )}
                            </OptionsWrapper>
                        )}
                    </AddWrapper>
                </SearchWrapper>
                <ActionsWrapper
                    backgroundColor={theme.palette.primary.backgroundSecondary}
                >
                    <div>
                        {
                            // Placeholder.
                        }
                    </div>
                </ActionsWrapper>
                {children}
            </Wrapper>
        </div>
    );
};

export default ChartQueryBuilder;
