import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  autoComplete: {
    width: "100%",
  },
  input: {
    width: "calc(100% - 1rem)",
    height: "2rem",
    padding: "0.25rem 0.5rem",
    backgroundColor: "white",
    border: "1px solid rgb(230, 230, 230)",
    borderBottom: "1px solid rgb(200, 200, 200)",
    marginBottom: "0.5rem",
    "&::before": {
      border: "none",
    },
  },
  label: {
    fontWeight: 400,
    fontSize: "1rem",
    margin: "1rem 0 0 0",
  },
  zip: {},
  city: {},
  container: {
    padding: "0.1rem",
  },
}));
