import { getApi } from "api/axios";
import { errorToast } from "components/common/Toast/Toast";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "redux/slices/UserSlice";
import AuthService from "services/AuthService";

export const useUserApi = () => {
  const dispatch = useDispatch();

  const [user, setUser] = useState(null);

  const [allUsers, setAllUsers] = useState();
  const [userLoading, setUserLoading] = useState(false);
  const [userError, setUserError] = useState(null);
  const [redirectOnSuccess, setRedirectOnSuccess] = useState(null);

  const isOk = (statusCode) => statusCode >= 200 && statusCode < 300;

  const getAllUsers = async ({ maintainers, shopOwner }) => {
    try {
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/admin/users`);
      if (status === 200) {
        setAllUsers(
          data.filter(
            (user) =>
              user.username !== "system-agent" &&
              user.username !== "dt-admin" &&
              user.username !== "printer-admin" &&
              !maintainers?.find(
                (maintainer) => maintainer.user.username === user.username
              ) &&
              user.username !== shopOwner.username
          )
        );

        // setAllUsers(
        //   data.filter((user) =>
        //     maintainers?.filter(
        //       (maintainer) => user.username !== maintainer.user.username
        //     )
        //   )
        // );
      } else {
        setAllUsers(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getCurrentUser = async () => {
    try {
      setUserError(null);
      setUserLoading(true);
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/admin/users/current`);
      if (status === 200) {
        //Test user privilages
        //Test superadmin user!!!!
        //const user = { ...data, roles: ["superadmin"] };
        //Test maintainer user!!!!
        //const user = { ...data, roles: ["maintainer", "printer"] };
        const user = { ...data };
        dispatch(setCurrentUser(user));
        setUserLoading(false);
        setUser(user);
      } else {
        setUserLoading(false);
        setUser(null);
        dispatch(setCurrentUser(null));
      }
      return { status: status, user: data };
    } catch (e) {
      setUserError(e);
      setUserLoading(false);
      console.log(e);
    }
  };

  const deleteCurrentUser = async () => {
    try {
      setUserError(null);
      setUserLoading(true);
      await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).delete(`/admin/users/current`);
      AuthService.doLogout();
    } catch (e) {
      errorToast("Sikertelen fiók törlés!");
      console.log(e);
    }
  };

  const subscribeNewsletter = async ({ value }) => {
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).post(`/users/newsletter?email=${value.contactEmail}&username=${value.contactFirstName + value.contactLastName}&newsletterId=2`);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  const postUser = async ({
    username,
    password,
    emailConfirm,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhone,
    contactCountry,
    contactCity,
    contactZipCode,
    contactStreetAndNumber,
    companyName,
    billingVatNumber,
    billingAccountNumber,
    billingRegisterNumber,
    billingCountry,
    billingCity,
    billingZipCode,
    billingStreetAndNumber,
    billingVendor,
    APIKey,
    clientId,
    resetData,
  }) => {
    try {
      setUserError(null);
      setUserLoading(true);
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).post(`/users`, {
        username: username,
        password: password,
        active: true,
        billingInfo: {
          companyName: companyName,
          // Commented part: this will be needed not companyName
          // lastName: billingLastName,
          // firstName: billingFirstName,
          vatNumber: billingVatNumber,
          bankAccountNumber: billingAccountNumber,
          registrationNumber: billingRegisterNumber,
          // billingVendor: billingVendor,
          // APIKey: APIKey,
          // clientId: clientId,
          billingAddress: {
            country: billingCountry,
            city: billingCity,
            zipCode: billingZipCode,
            streetAndNumber: billingStreetAndNumber,
          },
        },
        contact: {
          firstname: contactFirstName,
          lastname: contactLastName,
          email: contactEmail,
          phone: `+36${contactPhone}`,
          address: {
            country: contactCountry,
            city: contactCity,
            zipCode: contactZipCode,
            streetAndNumber: contactStreetAndNumber,
          },
        },
        groups: ["users"],
        roles: [],
        ownedShops: [],
        maintainedShops: [],
      });
      if (isOk(status)) {
        setUser(data);
        setRedirectOnSuccess(true);
        resetData();
      }
      setUserLoading(false);
    } catch (e) {
      setUserLoading(false);
      setUserError(e.response.data.message);
    }
  };

  const putUser = async ({
    username,
    password,
    emailConfirm,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhone,
    contactCountry,
    contactCity,
    contactZipCode,
    contactStreetAndNumber,
    billingLastName,
    billingFirstName,
    billingVatNumber,
    billingAccountNumber,
    billingRegisterNumber,
    billingCountry,
    billingCity,
    billingZipCode,
    billingStreetAndNumber,
  }) => {
    try {
      setUserError(null);
      setUserLoading(true);
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).put(`/users`, {
        username: username,
        password: password,
        billingInfo: {
          lastName: billingLastName,
          firstName: billingFirstName,
          vatNumber: billingVatNumber,
          accountNumber: billingAccountNumber,
          registerNumber: billingRegisterNumber,
          billingAddress: {
            country: billingCountry,
            city: billingCity,
            zipCode: billingZipCode,
            streetAndNumber: billingStreetAndNumber,
          },
        },
        contact: {
          firstname: contactFirstName,
          lastname: contactLastName,
          email: contactEmail,
          phone: contactPhone,
          address: {
            country: contactCountry,
            city: contactCity,
            zipCode: contactZipCode,
            streetAndNumber: contactStreetAndNumber,
          },
        },
        groups: ["users"],
        roles: [],
        ownedShops: [],
        maintainedShops: [],
      });
      if (isOk(status)) {
        setUser(data);
      }
      setUserLoading(false);
      return data;
    } catch (e) {
      setUserError(e);
    }
  };

  const putUserVatNumber = async (body) => {
    try {
      setUserError(null);
      setUserLoading(true);
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).put(`/admin/users`, body);
      if (isOk(status)) {
        setUser(data);
      }
      setUserLoading(false);
      return status;
    } catch (e) {
      setUserError(e);
    }
  };
  const putUserRegNumber = async (body) => {
    try {
      setUserError(null);
      setUserLoading(true);
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).put(`/admin/users`, body);
      if (isOk(status)) {
        setUser(data);
      }
      setUserLoading(false);
      return status;
    } catch (e) {
      setUserError(e);
    }
  };
  const putUserBankAccountNumber = async (body) => {
    try {
      setUserError(null);
      setUserLoading(true);
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).put(`/admin/users`, body);
      if (isOk(status)) {
        setUser(data);
      }
      setUserLoading(false);
      return status;
    } catch (e) {
      setUserError(e);
    }
  };

  const putUserPassword = async ({ user, password }) => {
    try {
      setUserError(null);
      setUserLoading(true);
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).put(`/admin/users`, {
        ...user,
        password: password,
      });
      if (isOk(status)) {
        setUser(data);
      }
      setUserLoading(false);
      return status;
    } catch (e) {
      setUserError(e);
    }
  };

  const putUserEmail = async ({ user, email }) => {
    try {
      setUserError(null);
      setUserLoading(true);
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).put(`/admin/users`, {
        ...user,
        contact: { ...user.contact, email: email },
        billingInfo: {
          ...user.billingInfo,
          billingAddress: { ...user.billingInfo.billingAddress },
        },
        roles: [...user.roles],
        groups: [...user.groups],
        ownedShops: [...user.ownedShops],
        maintainedShops: [...user.maintainedShops],
        partners: [...user.partners],
      });
      if (isOk(status)) {
        setUser(data);
      }
      setUserLoading(false);
      return status;
    } catch (e) {
      setUserError(e);
    }
  };
  const putUserName = async ({ user, firstname, lastname }) => {
    try {
      setUserError(null);
      setUserLoading(true);
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).put(`/admin/users`, {
        ...user,
        contact: { ...user.contact, firstname: firstname, lastname: lastname },
      });
      if (isOk(status)) {
        setUser(data);
      }
      setUserLoading(false);
      return status;
    } catch (e) {
      setUserError(e);
    }
  };
  const putUserBillingName = async (body) => {
    try {
      setUserError(null);
      setUserLoading(true);
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).put(`/admin/users`, body);
      if (isOk(status)) {
        setUser(data);
      }
      setUserLoading(false);
      return status;
    } catch (e) {
      setUserError(e);
    }
  };

  const putUserAddress = async (body) => {
    try {
      setUserError(null);
      setUserLoading(true);
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).put(`/admin/users`, body);
      if (isOk(status)) {
        setUser(data);
      }
      setUserLoading(false);
      return status;
    } catch (e) {
      setUserError(e);
    }
  };

  const putUserPhone = async (body) => {
    try {
      setUserError(null);
      setUserLoading(true);
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).put(`/admin/users`, body);
      if (isOk(status)) {
        setUser(data);
      }
      setUserLoading(false);
      return status;
    } catch (e) {
      setUserError(e);
    }
  };

  const putUserActive = async ({ user, active }) => {
    try {
      setUserError(null);
      setUserLoading(true);
      const { status, data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).put(`/admin/users`, {
        ...user,
        active: active,
        contact: { ...user.contact },
        billingInfo: {
          ...user.billingInfo,
          billingAddress: { ...user.billingInfo.billingAddress },
        },
        roles: [...user.roles],
        groups: [...user.groups],
        ownedShops: [...user.ownedShops],
        maintainedShops: [...user.maintainedShops],
        partners: [...user.partners],
      });
      if (isOk(status)) {
        setUser(data);
      }
      setUserLoading(false);
      return status;
    } catch (e) {
      setUserError(e);
    }
  };

  return {
    user,
    userLoading,
    userError,
    postUser,
    putUser,
    allUsers,
    getAllUsers,
    putUserVatNumber,
    putUserRegNumber,
    putUserBankAccountNumber,
    getCurrentUser,
    deleteCurrentUser,
    putUserPassword,
    putUserPhone,
    putUserBillingName,
    putUserAddress,
    putUserEmail,
    putUserActive,
    putUserName,
    redirectOnSuccess,
    subscribeNewsletter
  };
};
