import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
  },
  depositContext: {
    flex: 1,
    fontFamily: `${theme.typography.main}, Sans-serif, Arial`,
  },
  amount: {
    cursor: "pointer",
  },
}));
