/** @format */

import Grid from "@material-ui/core/Grid";

import InvoiceVendorForm from "components/PhotoAdminComponents/Settings/Invoicing/InvoiceVendorForm/InvoiceVendorForm";
import { useInvoicingSettingsAPI } from "./hooks/useInvoicingSettingsAPI";
import { useEffect, useState } from "react";
import {
    CircularProgress,
    Slide,
    Typography,
    useTheme,
} from "@material-ui/core";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import InvoiceVendorCard from "components/PhotoAdminComponents/Settings/Invoicing/InvoiceVendorCard/InvoiceVendorCard";
import CreateInvoiceVendorCard from "components/PhotoAdminComponents/Settings/Invoicing/CreateInvoiceVendorCard/CreateInvoiceVendorCard";
import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";
import ModifierInvoiceVendors from "components/PhotoAdminComponents/Settings/Invoicing/ModifierInvoiceVendors/ModifierInvoiceVendors";

const Invoicing = () => {
    const {
        getInvoiceVendors,
        invoiceVendors,
        invoiceVendorsLoading,
        invoiceVendorsError,
        postInvoiceVendor,
        invoiceVendorLoading,
        putInvoiceVendor,
        deleteInvoiceVendor,
        modifiers,
    } = useInvoicingSettingsAPI();

    const theme = useTheme();
    const classes = useStyles(theme);
    const shopId = useSelector(
        (state) => state.nonPersistedReducers?.shopSliceReducer?.shopId
    );
    const user = useSelector(
        (state) => state.nonPersistedReducers?.userSliceReducer?.user
    );

    const [newVendor, setNewVendor] = useState(false);
    const [editVendor, setEditVendor] = useState(false);

    const [deleteVendor, setDeleteVendor] = useState(null);

    useEffect(() => {
        getInvoiceVendors({});
    }, [shopId]);

    const containerRef = useRef(null);

    if (invoiceVendorsError) {
        return (
            <Grid container>
                <Typography variant="body2" color="primary">
                    Hiba történt. Kérjük próbálja újra később.
                </Typography>
            </Grid>
        );
    }

    if (invoiceVendorsLoading) {
        return (
            <Grid container>
                <Typography variant="body2" color="primary">
                    Kérem várjon...
                </Typography>
            </Grid>
        );
    }

    return (
        <Grid container style={{ margin: "1rem 0.5rem" }}>
            <Grid item xs={12}>
                <Typography className={classes.desktopTitle}>
                    Számlakibocsátó szolgáltatások
                </Typography>
            </Grid>
            {newVendor ? (
                <Grid
                    item
                    innerRef={containerRef}
                    xs={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Slide
                        container={containerRef.current}
                        direction="up"
                        in={true}
                        timeout={700}
                    >
                        <Grid
                            key={`invoice_vendor_creating_card`}
                            item
                            xs={12}
                            md={8}
                            lg={6}
                        >
                            <InvoiceVendorForm
                                user={user}
                                handleSave={postInvoiceVendor}
                                invoiceVendor={null}
                                loading={invoiceVendorLoading}
                                setOpen={() => setNewVendor(false)}
                            />
                        </Grid>
                    </Slide>
                </Grid>
            ) : editVendor ? (
                <Grid
                    item
                    innerRef={containerRef}
                    xs={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Slide
                        container={containerRef.current}
                        direction="up"
                        in={true}
                        timeout={700}
                    >
                        <Grid
                            key={`invoice_vendor_creating_card`}
                            item
                            xs={12}
                            md={8}
                            lg={6}
                        >
                            <InvoiceVendorForm
                                handleSave={putInvoiceVendor}
                                invoiceVendor={editVendor}
                                loading={invoiceVendorLoading}
                                setOpen={() => setEditVendor(null)}
                            />
                        </Grid>
                    </Slide>
                </Grid>
            ) : (
                <Grid
                    item
                    innerRef={containerRef}
                    xs={12}
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                    }}
                >
                    {(shopId === 132 ||
                        invoiceVendors?.length === 0 ||
                        modifiers?.find(
                            (m) =>
                                m.deliveryTypeDTO === "LELKES_SELF_PRINT" ||
                                m.modifierUriSegment?.includes("lelkesprint")
                        )) && (
                        <CreateInvoiceVendorCard
                            toggleCreateVendor={() => setNewVendor(true)}
                        />
                    )}

                    {invoiceVendors?.map((invoiceVendor) => (
                        <InvoiceVendorCard
                            invoiceVendor={invoiceVendor}
                            setEditVendor={setEditVendor}
                            toggleSure={() => {
                                setDeleteVendor(invoiceVendor);
                            }}
                        />
                    ))}
                </Grid>
            )}
            <ConfirmDialog
                open={deleteVendor}
                onClose={() => setDeleteVendor(null)}
                onOk={() => {
                    deleteInvoiceVendor({
                        vendorId: deleteVendor?.id,
                        setOpen: () => setDeleteVendor(null),
                    });
                }}
                disabled={invoiceVendorLoading}
                multipleButtons
                closeButtonText={"Mégsem"}
                functionButtonColor={theme.palette.primary.error}
                functionButtonText={
                    invoiceVendorLoading ? (
                        <CircularProgress size={24} />
                    ) : (
                        "Törlés"
                    )
                }
                title={`Figyelem! Törlés után a törölt számlakibocsátó által létrehozott korábbi számláid letöltésére nem lesz lehetőséged rendszerünkből, ezt csupán a számlakibocsátó rendszerén keresztül tudod majd megtenni. Biztosan törölni akarod a(z) ${deleteVendor?.name} nevű számlakibocsátódat?`}
            />
            {invoiceVendors?.length > 1 && !editVendor && !newVendor && (
                <Grid
                    item
                    xs={12}
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        style={{
                            fontFamily: theme.typography.primary,
                            color: theme.palette.primary.main,
                            width: "100%",
                            textAlign: "center",
                            fontSize: "1.3rem",
                            margin: "1rem 0",
                        }}
                    >
                        Egyedi számlázás beállítása
                    </Typography>
                    <ModifierInvoiceVendors />
                </Grid>
            )}
        </Grid>
    );
};

export default Invoicing;
