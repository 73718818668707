import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import ChartQueryBuilder from "components/common/ChartQueryBuilder/ChartQueryBuilder";
import { useReportChartLogics } from "./useReportChartLogics";

const ReportChart = () => { 
  const { fetchOrderedItems, orderedData, stateB } = useReportChartLogics();

  useEffect(() => {
    fetchOrderedItems();
  }, []);

  return (
    <div>
      <ChartQueryBuilder
        chartName={"reportChart"}
        siteType={"admin"}
        accountingData={orderedData}
        refetch={fetchOrderedItems}
      >
        <div style={{ marginTop: "1.5rem" }}>
          <ReactApexChart
            options={stateB?.options}
            series={stateB.series}
            type="line"
            height={350}
          />
        </div>
      </ChartQueryBuilder>
    </div>
  );
};

export default ReportChart;
